<template>
  <div>
    <div class='relative py-4 lg:py-12'
      :style='`height:${mainImageHeightPx}px`'>
      <content-background-cover
        background-color='#EEEEEE'
        :background-image='eventMainPageImageBg'
        :top-offset='0'
        :height='mainImageHeightPx'
        class='z-0 background-main-image' />
        &nbsp;
    </div>
    <div 
      class='relative pt-4 pb-16 z-10'>
      <content-background-cover
        :top-offset='0'
        class='z-0'
        style='background: linear-gradient(153.43deg, rgba(25, 154, 201, 0.2) 12.65%, rgba(242, 195, 195, 0.2) 72.59%), #FFFFFF;' />
      <div class='overlapping-section flex flex-col lg:flex-row justify-between items-stretch gap-x-8 py-4 lg:p-8 lg:shadow-xl'>
        <div class='w-full lg:w-1/2 flex-grow-0 flex-shrink-0 mb-8 lg:mb-0'>
          <notices-preview-list class='px-4' />
        </div>
        <div class='flex-grow-0 w-full lg:w-1/2 px-4 lg:px-0'>
          <div class='row-centered justify-star mb-2'>
            <h2 class='text-lg font-semibold'>학술대회 주요 일정</h2>
          </div>
          <div class='flex flex-col lg:flex-row lg:items-centered lg:justify-end gap-x-2 gap-y-2 lg:gap-y-0'>
            <main-important-date-card 
              v-for='(importantDate, index) in eventImportantDates'
              :key='`importantDate-${index}-${importantDate.title}`'
              :important-date='importantDate'
              main-card-width='33%'
              card-date-height='8rem'
              card-title-height='8rem'
              card-title-text-size='1.2rem' />
          </div>
        </div>
      </div>
      <div class='mt-4 lg:mt-12 px-4 py-8 bg-gray-50 lg:bg-white lg:shadow-xl'>
        <div class='lg:px-6 text-xs lg:text-sm overflow-auto'>
          <table class='w-full border-separate border-spacing-x-0 border-spacing-y-3'>
            <thead>
              <tr>
                <th class='px-2 py-1 whitespace-nowrap text-left' style='font-size:14px;'>
                  평점안내
                  <button 
                    @click='goToScorePage'
                    class='text-xs hover:bg-gray-100 p-2 rounded-md'>
                    더보기
                  </button>
                </th>
                <th class='px-2 py-1 whitespace-nowrap font-medium text-left'>
                  대한의사협회
                </th>
                <th class='px-2 py-1 whitespace-nowrap font-medium text-left'>
                  내과전공의 외부학술회의(학술대회)	
                </th>
                <th class='px-2 py-1 whitespace-nowrap font-medium text-left'>
                  대한비만학회 비만전문인정의
                </th>
                <th class='px-2 py-1 whitespace-nowrap font-medium text-left'>
                  임상영양사 전문연수교육(CPD)
                </th>
                <th class='px-2 py-1 whitespace-nowrap font-medium text-left'>
                  대한운동사협회	
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class='bg-white'>
                <th class='px-2 py-3 text-left'>
                  3월 17일 <span class='font-normal text-gray-500'>(금요일)</span>
                </th>
                <td class='px-2 py-3'>
                  최대 <span class='font-semibold'>1</span>평점
                </td>
                <td class='px-2 py-3'>
                  없음
                </td>
                <td class='px-2 py-3'>
                  최대 <span class='font-semibold'>1</span>평점
                </td>
                <td class='px-2 py-3'>
                  없음
                </td>
                <td class='px-2 py-3'>
                  없음
                </td>
              </tr>
              <tr class='bg-white'>
                <th class='px-2 py-3 text-left'>
                  3월 18일 <span class='font-normal text-gray-500'>(토요일)</span>
                </th>
                <td class='px-2 py-3'>
                  최대 <span class='font-semibold'>6</span>평점
                </td>
                <td class='px-2 py-3 whitespace-nowrap'>
                  최대 <span class='font-semibold'>2</span>평점
                  <span class='text-xs text-red-400'>*오전, 오후 각 1점으로 산정</span>
                </td>
                <td class='px-2 py-3'>
                  최대 <span class='font-semibold'>6</span>평점
                </td>
                <td class='px-2 py-3'>
                  <span class='font-semibold'>5</span>평점
                </td>
                <td class='px-2 py-3'>
                  <span class='font-semibold'>25</span>평점
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <important-lectures 
        :lectures='eventImportantLectures'
        class='mt-4 lg:mt-20 px-4 lg:px-0' />
    </div>
    <welcome-sponsors-carousel class='mt-2' />
    <on-load-modal
      v-for='(onLoadModal, index) in onLoadModals'
      :key='`onLoadModal-${index}`'
      :onLoadModal='onLoadModal' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import NoticesPreviewList      from '@/components/NoticesPreviewList.vue'
import MainImportantDateCard   from '@/components/MainImportantDateCard.vue'
import WelcomeSponsorsCarousel from '@/components/welcome/WelcomeSponsorsCarousel.vue'
import ImportantLectures       from '@/components/kossoasc/ImportantLectures.vue'
import OnLoadModal             from '@/components/OnLoadModal.vue'

export default {
  name: 'Main',
  components: {
    ImportantLectures,
    MainImportantDateCard,
    WelcomeSponsorsCarousel,
    ContentBackgroundCover,
    NoticesPreviewList,
    OnLoadModal,
  },
  computed: {
    ...mapGetters('events', [
      'eventImportantDates',
      'eventImportantLectures',
      'eventMainPageImageBg',
      'onLoadModals',
    ]),
    mainImageHeightPx () {
      let width = window.innerWidth
      if (width < 800) { // handle case for mobile profile
        return 400
      } else {
        return (width / 2)
      }
    },
  },
  methods: {
    goToScorePage () {
      this.$router.push({ name: 'ScoringCredits' })
    },
  }
}
</script>

<style lang='scss' scoped>
.overlapping-section {
  background-color: #FFFFFFF8; 
  margin-top: 0;
}

.background-main-image {
  background-position: 33% top; 
  background-size: 50% auto;
}

@media (min-width: 1024px) {
  .overlapping-section {
    margin-top: -18rem;
  }

  .background-main-image {
    background-position: center; 
  }
}
</style>
