<template>
  <div class='flex flex-col lg:flex-row lg:justify-center items-start lg:items-center gap-y-4 lg:gap-x-1'>
    <div v-for='(lecture, index) in lectures'
      :key='`lecture-card-${index}`'
      class='important-lecture px-4 pt-6 lg:px-8 lg:pt-10 relative'
      :style='lecture.cardStyle'>
      <h3 
        class='uppercase font-semibold text-xl lg:text-2xl' 
        :style='`color: ${lecture.mainTextColor};`'>
        {{lecture.date}}
      </h3>
      <h4 
        class='font-light text-xl lg:text-2xl' 
        :style='`color: ${lecture.secondaryTextColor};`'>
        {{lecture.time}}
      </h4>
      <h5 
        class='absolute text-lg' 
        :style='`color: ${lecture.secondaryTextColor};`'>
        {{lecture.location}}
      </h5>
      <h1 
        class='mt-12 lg:mt-20 font-bold text-3xl' 
        :style='`color: ${lecture.mainTextColor};`'>
        {{lecture.title}}
      </h1>
      <h4 
        class='mt-2 lg:mt-4 font-medium lg:font-semibold text-lg leading-5' 
        :style='`color: ${lecture.mainTextColor};`'>{{lecture.description}}</h4>
      <h4 
        class='mt-16 font-normal text-2xl' 
        :style='`color: ${lecture.mainTextColor};`'>
        {{lecture.speakerName}}
      </h4>
      <h5 
        class='text-base'
        :style='`color: ${lecture.secondaryTextColor};`'>
        {{lecture.speakerAffiliation}}
      </h5>
      <img 
        :src='lecture.speakerImage' 
        class='absolute bottom-0'
        :class='speakerImageClasses(lecture.speakerImageCssClasses)'
        :style='lecture.speakerImageCssStyle' />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportantLectures',
  props: {
    lectures: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    speakerImageClasses (configClasses) {
      return configClasses ? configClasses : 'right-8 w-32 lg:ml-16'
    },
  }
}
</script>

<style lang='scss' scoped>
  .important-lecture {
     width: 100%;
     height: 24rem;
  }

  @media (min-width: 1024px) {
    .important-lecture {
       width: 28rem;
       height: 40rem;
    }
  }

</style>
